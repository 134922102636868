*
{
    margin: 0;
    padding: 0;
}


body
{
    margin: 0;
    width:100vw;
    height:100vh;
    overflow: hidden;
    background:rgb(0,0,0);

}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.webgl-pixi
{
    position: fixed;
    top: 0;
    left: 0;
    background-color: red;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;

    /* ... */
    transform: scaleX(0.0);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
}

.point .label
{
    width: 16px;
    height: 16px;
    /* padding: 2px 5px 2px 5px; */
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 11pt;
    text-decoration: none;
    border-radius: 50%;
    background: white;
    opacity: 1.0;
    /* color: #ffffff; */
    color: #000;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    /* opacity:0; */

    transform: scale(0);
    transition: transform 0.4s;

    -webkit-transform: scale(0);
    -webkit-transition: transform 0.4s;
}

.point .label:hover
{
    background: #ff0000;
    /* border: 2px solid #ffffff; */
    color: #ffffff;
}

.point.visible .label
{
    transform: scale(1);
    -webkit-transform: scale(1);
}

.point.pulse .label
{
    animation: pulse 2s ease-out infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);

        -webkit-transform: scale(0.95);
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);

        -webkit-transform: scale(1);
		-webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

        -webkit-transform: scale(0.95);
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.point .label .icon
{
    max-width: 24px;
    max-height: 24px;
    display: block;
    margin: 0 auto;
}

.point .text
{
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 30px;
    left: -120px;
    width: 200px;
    /* width: auto; */
    padding: 20px;
    border-radius: 8px;

    /* background: #00000077; */
    /* border: 1px solid #ffffff77; */
    /* color: #ffffff; */

    text-align: center;
    background: #ffffffee;
    /* border: 1px solid #ffffff77; */
    color: #000000;

    line-height: 1.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 14px;
}

.point:hover .text
{
    opacity: 1;
    transition: opacity 0.3s;
}

.ui-action-bar
{
    padding-left: 16px;
    margin: 16;

    position: absolute;
    bottom: 52px;
    transform: scale(1, 0);
}

.ui-action-bar.visible
{
    transform: scale(1, 1);
    transition: transform 0.1s;
}

.ui-action-bar .ui-action-btn
{
    /* float:left; */
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-bottom:8px;
    margin-top:8px;
    border-radius: 50%;
    background: #fff;

    transform: scale(1);
    transition: transform 0.1s;

    box-shadow: rgba(159, 190, 221, 0.5) 0px 2px 16px
}


.linkedin svg
{
    width:40%;
    margin: 0 auto;
    display: block;
}

.twitter svg
{
    width:40%;
    margin: 0 auto;
    display: block;
}

.arkit img
{
    width:70%;
    margin: 0 auto;
    display: block;
}

.ui-action-btn.linkedin:hover, .ui-action-btn.linkedin svg:hover
{
    fill:#fff;
}

.ui-action-btn.linkedin:hover
{
    background:#0077B5;
    transform: scale(1.2);
    /* transition: transform 0.15s; */
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

.ui-action-btn.twitter:hover, .ui-action-btn.twitter svg:hover
{
    fill: #fff;
}

.ui-action-btn.twitter:hover
{
    background:#1DA1F2;
    transform: scale(1.2);
    /* transition: transform 0.15s; */
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

.ui-action-btn.arkit:hover, .ui-action-btn.arkit svg:hover
{
    fill: #fff;
}

.ui-action-btn.arkit:hover
{
    /* background:#000; */
    transform: scale(1.2);
    /* transition: transform 0.15s; */
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

.dialog-box-container
{
    position:absolute;
    bottom:64px;
    text-align: center;
    width: 100%;
    /* height:100%; */
    transform: scale(0, 0);
    transition: transform 0.2s;

    z-index: 20;
}

.dialog-box-container.visible
{
    transform: scale(1, 1);
}

.dialog-box
{
    display:inline-block;

    /* height:auto; */
    position:relative;
    /* margin: auto auto 128px auto; */
    margin:auto;

    width:420px;

    /* border:3px solid #000; */

    transform: scale(0, 0);
    transition: transform 0.2s;
    text-align: left;

    line-height: 1.7em;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 600;

    /* color:#00aaee; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.dialog-box.selection
{
    background:rgb(255, 233, 200);
}

.dialog-box.disabled
{
    pointer-events: none;
}

.dialog-box.visible
{
    transform: scale(1, 1);
}

.dialog-box-content {
    border-radius: 20px;
    background:#fff;
    padding: 24px;
    text-align: center;
    font-size: 18px;

}

.dialog-box-content span span {

    opacity: 0;
}

.dialog-box-content span span.visible {

    opacity: 1;
}

.dialog-box span span.small {
    font-size: 14px;
}

.dialog-box-buttons {

    margin:8px auto auto auto;
    color:#444;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row-reverse; */
    opacity: 0;
    transition: opacity 0.3s;
}

.dialog-box-buttons.appear{

    opacity: 1;
}

.dialog-box-btn {
    cursor: pointer;
    border-radius: 20px;
    text-align: center;
    /* background:#fff; */
    flex:1 1 48%;
    margin: 2px;
    color: #000;

    background-color:rgba(255,255,255,0.5);/*background color and opacity together*/
}


.dialog-box-btn:hover {

    background-color: #f00;
    color:#fff;
}

.dialog-box-btn span {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;

}


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

  @media (min-width: 1281px) {


  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {


  }

  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) {


  }

  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {


  }

  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {


  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {

    .ui-action-bar {
        bottom: 32px;
        padding-left: 8px;
    }

    .dialog-box {
        width: 95%;
    }

    .dialog-box span span.small {
        font-size: 14px;
    }

    .dialog-box-content {
        font-size: 18px;
    }

    .dialog-box-buttons {
        font-size: 14px;
        /* display: block; */
    }

    .dialog-box-container
    {
        bottom: 16px;
    }
  }

.beta-label
{
    position:absolute;
    right: 8px;
    bottom: 8px;

    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    z-index: 10;
}
